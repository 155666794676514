export default {
  onRequest(request, _requestAction, store) {
    if (store.getState().user.currentUser) {
      const { token } = store.getState().user.currentUser.data.tokens.access;
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return request;
  },
};
