/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/named
import UserActionTypes from "./user.types";

const { SIGN_IN, SET_CURRENT_USER, SIGN_OUT } = UserActionTypes;

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const logout = () => ({
  type: SIGN_OUT,
  payload: {},
});

export const login = (email, password) => ({
  type: SIGN_IN,

  request: { method: "post", data: { email, password }, url: `/v1/auth/login` },

  meta: {
    getData: (data) => ({ ...data }),
  },
});
