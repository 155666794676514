/* eslint-disable import/no-import-module-exports */
import axios from "axios";
import { handleRequests } from "@redux-requests/core";
import { createDriver } from "@redux-requests/axios";
import loggerMiddleware from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./root-reducer";

function configureStore(preloadedState) {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(
      axios.create({
        baseURL: "https://forms.eyouthlearning.com",
      })
    ),
  });

  const persistConfig = {
    key: "root",
    storage,
    // whitelist: ["user"],
  };
  const rootReducer = persistReducer(
    persistConfig,
    combineReducers({ ...reducers, requests: requestsReducer })
  );

  const middlewares = [loggerMiddleware, thunkMiddleware, ...requestsMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./root-reducer", () => store.replaceReducer(rootReducer));
  }
  const persistor = persistStore(store);

  return { store, persistor };
}

export default configureStore({});
